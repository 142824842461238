import { Modal, Tabs } from "antd";
import React from "react";
import CompensationTab from "./CompensationTab";
import RefundTab from "./RefundTab";
import AgentCompensate from "./AgentCompensate";
import DriverCompensate from "./DriverCompensate";
import RequestRefundTab from "./RequestRefundTab";

function OrderRefundModal(props) {
  const { status, setStatus ,selectedOrder} = props;
  return (
    <Modal
      title="Order Refund/Compnesation"
      destroyOnClose
      getContainer={false}
      open={status}
      onCancel={() => {
        setStatus(false);
      }}
      footer={null}
      centered
      width={"100%"}
    >
      <div
        style={{
          minHeight: "75vh",
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: `Refund`,
              key: "1", 
              children: <RefundTab
              selectedOrder={selectedOrder}
              />,
            },
            {
              label: `Request Refund`,
              key: "2",
              children: <RequestRefundTab
              selectedOrder={selectedOrder}
              />,
            },
            {
              label: `Compensate`,
              key: "3",
              children: <CompensationTab 
              selectedOrder={selectedOrder}
              />,
            },
            {
              label: `Agent Compensate`,
              key: "4",
              children: <AgentCompensate 
              selectedOrder={selectedOrder}
              />,
            },
            {
              label: `Driver Compensate`,
              key: "5",
              children: <DriverCompensate 
              selectedOrder={selectedOrder}
              />,
            },
          ]}
        />
      </div>
    </Modal>
  );
}

export default OrderRefundModal;
