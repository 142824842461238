/* eslint-disable no-restricted-globals */
import { Button, Form, Input } from 'antd';
import Wave from 'react-wavify';
import React from 'react';

import useLocalStorage from '../configs/localStorage';
import { useAxiosPost } from '../configs/axios';

const Login = () => {
  const [auth, setAuth] = useLocalStorage('auth', null);
  const {
    request: loginRequest,
    loading: loginLoading,
    error: loginError,
  } = useAxiosPost('/auth/login');

  const onLogin = async (values) => {
    try {
      const loginData = await loginRequest({
        username: values.username,
        password: values.password,
      });
      setAuth({
        token: loginData.data.token,
        user: loginData.data.user,
      });
      location.replace('/');
    } catch (error) {
    }
  };

  if (auth) {
    return location.replace('/');
  }
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1,
      }}
    >
      <div
        style={{
          background: 'rgb(0,100,192)',
          width: '100%',
          position: 'absolute',
          zIndex: -1,
          height: '100vh',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
        }}
      >
        <img
          alt="lezzoo"
          src="https://lezzoo.com/assets/lezzoo_text_logo_white.png"
          style={{
            position: 'absolute',
            top: '50px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '250px',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '40px',
            borderRadius: '20px',
            height: '450px',
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '40px',
              fontWeight: 'bold',
              lineHeight: '0.8',
            }}
          >
            Login
          </h1>
          <p
            style={{
              fontWeight: 'bold',
              opacity: 0.5,
              fontSize: '20px',
            }}
          >
            Enter your credentials
          </p>
          <Form
            layout="vertical"
            style={{
              width: '100%',
              marginTop: '50px',
            }}
            onFinish={onLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input size="large" placeholder="Username" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              name="password"
            >
              <Input size="large" placeholder="Password" type="password" />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              style={{ width: '100%', borderRadius: '40px' }}
              loading={loginLoading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form>
          <div
            style={{
              color: 'red',
              marginTop: '10px',
            }}
          >
            {loginError && loginError.response.data.msg}
          </div>
        </div>

        <Wave
          fill="white"
          style={{
            height: '20%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          }}
          className="wave"
          options={{
            height: 0,
            amplitude: 40,
            speed: 0.2,
            points: 8,
          }}
        />
        <Wave
          fill="white"
          style={{
            height: '25%',
            opacity: 0.5,
          }}
          className="wave"
          options={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 0,
            amplitude: 40,
            speed: 0.2,
            points: 6,
          }}
        />
        <Wave
          fill="white"
          style={{
            height: '29%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0.2,
          }}
          className="wave"
          options={{
            height: 0,
            amplitude: 30,
            speed: 0.2,
            points: 4,
          }}
        />
      </div>
    </div>
  );
};

export default Login;
