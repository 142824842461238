import {
  PieChartFilled,
  MessageFilled,
  TagFilled,
  DollarOutlined,
  UserOutlined,
  ThunderboltOutlined,
  SortAscendingOutlined,
  ContainerOutlined,
  SecurityScanOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import React from 'react';
import _ from 'lodash';
import './SidebarContent.css';
import { useNavigate } from 'react-router-dom';
import {
  UilMapMarkerEdit,
  UilCommentAdd,
  UilMap,
  UilAward,
  UilBill
} from '@iconscout/react-unicons';
import useLocalStorage from '../../configs/localStorage';

function SidebarContent() {
  const [auth] = useLocalStorage('auth', null);
  const navigate = useNavigate();
 

  const items = [
    {
      key: '1',
      icon: <PieChartFilled />,
      label: 'Orders',
      url: '/',
    },
    {
      key: '2',
      icon: <MessageFilled />,
      label: 'Chatrooms',
      url: '/chat',
    },
    {
      key: '3',
      icon: <UserOutlined />,
      label: 'Customers',
      url: '/customers',
    },
    {
      key: '4',
      icon: <TagFilled />,
      label: 'User Status',
      url: '/user-status',
    },
    {
      key: '5',
      icon: <UilMapMarkerEdit />,
      label: 'Customer Address',
      url: '/customer-address',
    },
    {
      key: '7',
      icon: <UilCommentAdd />,
      label: 'Predefined Messages',
      url: '/predefined-messages',
    },
    {
      key: '8',
      icon: <ThunderboltOutlined />,
      label: 'Broosk',
      url: '/broosk',
    },
    {
      key: '9',
      icon: <SortAscendingOutlined />,
      label: 'Merchant Hub Sort',
      url: '/merchant-hub-sort',
    },
    {
      key: '10',
      icon: <CloseSquareOutlined  />,
      label: 'Merchant Hub Close',
      url: '/merchant-hub-close',
    },
    {
      key: '11',
      icon: <ContainerOutlined />,
      label: 'Vouchers',
      url: '/vouchers',
    },
    {
      key: '12',
      icon: <DollarOutlined />,
      label: 'Send Lezzoo Pay',
      url: '/send-lezzoopay',
    },
    {
      key: '13',
      icon: <UilMap />,
      label: 'Hub Closure',
      url: '/hub-closure',
    },
    {
      key: '14',
      icon: <SecurityScanOutlined />,
      label: 'Permissions',
      url: '/permissions',
    },
    {
      key: '15',
      icon: <UilAward />,
      label: 'VIP',
      url: '/vip-merchants',
    },
    {
      key: '16',
      icon: <MessageFilled />,
      label: 'QA',
      url: '/quality-assurance',
    },
    {
      key: '17',
      icon: <UilBill />,
      label: 'Request Refund',
      url: '/request-refunds',
    }
    
  ];

 

  const pers = auth?.user.role.permissions.map((per) => per.name);
  _.remove(items, (route) => !_.includes(pers, route.url));

  return (
    <Row>
      <Col span={24} align="middle">
        <img
          src="https://play-lh.googleusercontent.com/VRYmahhs3v6rARILk40Rf2dmUKOWJXwNjNi7cUme0iytSYd6YWNb5XtmGa6oZqExhQ"
          style={{
            width: '70%',
            height: 'auto',
            marginBlock: '1rem',
            transition: 'all 0.2s',
            borderRadius: '10px',
          }}
          alt="logo"
        />
      </Col>
      <Col span={24}>
        <Menu
          mode="inline"
          items={items}
          defaultSelectedKeys={
            [
              // items.find((item) => item.url === location.pathname).key,
            ]
          }
          style={{
            background: 'transparent',
            color: 'grey',
            fontWeight: 'bold',
          }}
          onClick={(e) => {
            const selectedItem = items.find((item) => item.key === e.key);
            navigate(selectedItem.url);
          }}
        />
      </Col>
    </Row>
  );
}

export default SidebarContent;
