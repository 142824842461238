import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const voucherEnumSlice = createSlice({
  name: 'enumSlice',
  initialState,
  reducers: {
    add: (state, action) => {
      if (action.payload != null && !state.data.length) {
        state.data.push(action.payload);
      }
    },
  },
});
