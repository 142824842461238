import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Tabs, message } from 'antd';
import React from 'react';
import { useAxiosPost } from '../../configs/axios';

function RefundOnlinePayment({ open, setOpen, orderId }) {
  function onClose() {
    setOpen(false);
  }
  const {request:refundRequest,loading:loadingRefund}=useAxiosPost('lezzoopay/refundOrder')
  const [form] = Form.useForm();
  const refundType = Form.useWatch('refund_type', form);
  const onFinish = async(values) => {
    const body = {
      orderId,
      refund_type:values.refund_type,
      amount:values.amount
    }
    try {
      await refundRequest(body);
      message.success('refund is successful')
    } catch {
      message.error("Failed to refund")
    }
  }
  return (
    <Modal
      title="Refund online payment"
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          refund_type: 'custom',
        }}
      >
        <Row gutter={[10, 10]} justify={'end'}>
          <Col span={24}>
            <Form.Item
              name="refund_type"
              rules={[
                {
                  required: true,
                  message: 'Please select a refund type!',
                },
              ]}
            >
              <Radio.Group>
                <Radio key={'custom'} value={'custom'}>
                  Custom
                </Radio>
                <Radio key={'full_refund'} value={'full_refund'}>
                  Full refund
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={'amount'}
              rules={[
                {
                  required: refundType == 'custom',
                  message: 'Please enter the amount',
                },
              ]}
            >
                          <InputNumber style={{
                  width:'100%'
              }} placeholder="Ex. 3000" disabled={refundType != 'custom'} />
            </Form.Item>
          </Col>
          <Col>
            <Button loading={loadingRefund}  onClick={onClose}>Cancel</Button>
          </Col>
          <Col>
            <Button loading={loadingRefund} htmlType="submit" type="primary">
              Refund
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default RefundOnlinePayment;
