import { Layout } from 'antd';
import React, { createContext, Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { Provider } from 'react-redux';
import _ from 'lodash';
import routes from './routes';
import SidebarContent from './components/layout/SidebarContent';
import HeaderContent from './components/layout/HeaderContent';
import Login from './pages/Login';
import Order from './pages/Order';
import useLocalStorage from './configs/localStorage';
import store from './store';
import { useAxiosGetChat } from './configs/axios';
import { initSocket } from './store/socket/socketGetter';

export const NotificationsContext = createContext();

// The following is required to stop "npm build" from transpiling mapbox code
// notice the exclamation point in the import.
// @ts-ignore
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { Header, Sider, Content } = Layout;

function App() {
  const [auth, setAuth] = useLocalStorage('auth', null);
  if (!auth?.user?.role?.permissions && auth != null) {
    setAuth(null);
  }
  const pers = auth?.user.role.permissions.map((per) => per.name);

  _.remove(routes, (route) => !_.includes(pers, route.path));
  const socket = initSocket(auth);
  const {
    data: notificationsData,
    setData: setNotificationsData,
    request: notificationsDataRequest,
  } = useAxiosGetChat('/chats/roomCounter', {});

  const changeChatRoomCounter = (
    roomType,
    roomRef,
    isForMessageSeen,
    messageSource,
    number,
  ) => {
    const updatedData = {
      chatSource: roomType,
      number,
      roomRef,
      isForMessageSeen,
      actionStatus: isForMessageSeen ? 'roomSeen' : 'newMessage',
    };

    if (!isForMessageSeen && messageSource === 'agent') {
      number = 0;
    }

    if (roomType == 'customer_general' || roomType == 'customer_agent') {
      setNotificationsData((prev) => {
        const customers = prev.customers;
        customers.countMessages += number;
        customers.countMessages = Math.max(0, customers.countMessages);
        customers[roomType] += number;
        customers[roomType] = Math.max(customers[roomType], 0);
        return {
          ...prev,
          customers,
          updatedData: updatedData,
        };
      });
    }
    if (roomType == 'merchant_agent') {
      setNotificationsData((prev) => {
        const merchants = prev.merchants;
        merchants.countMessages += number;
        merchants.countMessages = Math.max(0, merchants.countMessages);
        merchants[roomType] += number;
        merchants[roomType] = Math.max(merchants[roomType], 0);
        return {
          ...prev,
          merchants,
          updatedData: updatedData,
        };
      });
    }

    if (roomType == 'couriers' || roomType == 'orders') {
      setNotificationsData((prev) => {
        const drivers = prev.drivers;
        drivers.countMessages += number;
        drivers.countMessages = Math.max(0, drivers.countMessages);
        drivers[roomType] += number;
        drivers[roomType] = Math.max(drivers[roomType], 0);
        return {
          ...prev,
          drivers,
          updatedData: updatedData,
        };
      });
    }
  };
  useEffect(() => {
    if (window.location.pathname !== '/login') {
      notificationsDataRequest();
      socket?.on('counter-update', (data) => {
        const shouldUserSeeMessage =
        auth.user.isMonitorAgent || auth.user.isSuperAdmin || auth.user.isSupervisorAgent;
        const isRoomAssignedToUser =
          data?.roomAssignedTo?.toString() === auth.user._id?.toString();
        if (!(isRoomAssignedToUser || shouldUserSeeMessage)) {
          return;
        }

        changeChatRoomCounter(
          data.chatSource,
          data.roomRef,
          data.isForMessageSeen,
          data.messageSource,
          data.counter,
        );
      });
    }
    return () => {
      socket?.removeListener('counter-update');
    };
  }, []);

  const [collapsed, setCollapsed] = React.useState(true);
  if (window.location.pathname === '/login') return <Login />;
  if (window.location.pathname === '/order-details') return <Order />;
  return (
    <Provider store={store}>
      <BrowserRouter>
        <NotificationsContext.Provider
          value={[notificationsData, setNotificationsData]}
        >
          <Layout
            style={{
              minHeight: '100vh',
            }}
          >
            <Sider
              collapsed={collapsed}
              style={{
                zIndex: 1,
                background: '#fff',
                height: '100vh',
                position: 'fixed',
                overflow: 'auto',
                boxShadow: '0.4px 0.4px 20px #bfbdb644',
              }}
            >
              <SidebarContent />
            </Sider>
            <Layout>
              <Header
                style={{
                  background: 'white',
                  position: 'fixed',
                  zIndex: 1000,
                  width: collapsed ? 'calc(100% - 79px)' : 'calc(100% - 200px)',
                  marginLeft: collapsed ? '79px' : '200px',
                  transition: 'all 0.2s',
                  paddingInline: '10px',
                }}
              >
                <HeaderContent toggleSidebar={() => setCollapsed(!collapsed)} />
              </Header>
              <Content
                style={{
                  background: '#F8FBFF',
                  marginLeft: collapsed ? '79px' : '200px',
                  padding: '20px 10px',
                  marginTop: '64px',
                }}
              >
                <Routes>
                  {routes.map((route) => (
                    <Route
                      path={`${route.path}`}
                      key={route.key}
                      element={
                        <Suspense fallback={<>...</>}>
                          <route.component />
                        </Suspense>
                      }
                    />
                  ))}
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </NotificationsContext.Provider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
