
// const API_URL = 'https://operations-api.dev.lezzootech.com/api';
// const API_URL = 'http://localhost:4000/api';
// const API_URL = 'http://192.168.100.150:4000/api';
//  const API_URL = 'https://operations-api.lezzoodevs.com/api';
// const API_URL = 'https://operations-api.dev.lezzoodevs.com/api';

//  const API_URL = 'https://operations-api.lezzoodevs.com/api';
const API_URL = process.env.REACT_APP_API_URL;

export default API_URL;
// ayman ip
export const CHAT_URL = 'https://chatting-service.lezzootech.com';
// export const CHAT_URL = 'http://192.168.1.10:4011';

// local ip
// export const CHAT_URL = 'http://localhost:4011';


// this permission is for the user to create a new user
export const CreateUser = 'Create User';
// this permission is for the user to edit a new user
export const EditUser = 'Edit User';
// this permission is for the user to close  Merchant
export const CanCloseMerchant = 'Close Merchant';
// this permission is for the user to Reset password
export const ResetPassword = 'Reset Password';
// this permission is for the user to Delete User
export const DeleteUser = 'Delete User';
// this permission is for the user to Create Offline Order
export const CreateOfflineOrder = 'Create Offline Order';

// this permission is for the user to Create Offline Order
export const uploadFibOrders = 'Upload Fib Orders';
// this permission is for the user to subscribe customer to VIP
export const makeCustomerVIP = 'Make Customer VIP';

// this permission is for the user to show customer merchant chat
export const ShowCustomerMerchantChat = 'showCustomerMerchantChat';

// this permission is for the user to show merchant driver chat
export const ShowMerchantDriverChat = 'showMerchantDriverChat';

// this permission is for the user to show customer driver chat
export const ShowCustomerDriverChat = 'showCustomerDriverChat';
export const ShowCustomerAgent = 'ShowCustomerAgent';
export const ShowMerchantAgent = 'ShowMerchantAgent';
export const ShowDriver = 'ShowDriver';
export const ShowDriverSupport = 'ShowDriverSupport';
export const ShowDriverOrder = 'ShowDriverOrder';

export const MerchantPenalties= 'Merchant Penalties';
export const assignIssueToOrder= 'assignIssueToOrder';
export const showUrgentTabInOrder= 'showUrgentTabInOrder';
export const orderDetailsRefund= 'orderDetailsRefund';
export const orderDetailsRefundOnline= 'orderDetailsRefundOnline';


