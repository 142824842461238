import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from 'antd';
import React, { useReducer } from 'react';
import RemoteSelectForm from '../utility/RemoteSelectForm';
import { useAxiosPost } from '../../configs/axios';

const CreateTicketComponentButton = ({

  hideTitle,
}) => {
  const [form] = Form.useForm();
  const issueSource = Form.useWatch('issue_source', form);
  const { request: requestCreateTicket, loading: loadingCreateTicket } =
    useAxiosPost('/telegram-bot-ticket', {
      autoRun: false,
    });
  const initValue = {
    showCreateTicketModal: false,
  };

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );
  const onFinish = async (values) => {
    try {
      const body = {
        issue_type_id: values.issue_type_id.value,
        description: `${values.description}`,
        ref_id: issueSource,
        ref_value: values.field_value?.value,
      };
     
     
      await requestCreateTicket(body);
      message.success('operation successful');
    } catch {
      message.error('something went wrong');
    }
  };
  const onShowModal = () =>
    setState({
      showCreateTicketModal: true,
    });
  const onCloseModal = () =>
    setState({
      showCreateTicketModal: false,
    });
  const showValueOption = () => {
    switch (issueSource) {
      case 'is_merchant':
        return 'merchant_id';
      case 'is_driver':
        return `courier_id`;
      case 'is_customer':
        return `customer_id`;
    }
    return 'value';
  };
  const showLabelOption = (record) => {
    switch (issueSource) {
      case 'is_merchant':
        return `${record.merchant_id}-${record.merchant_name} - ${record.merchant_city}`;

      case 'is_driver':
        return `${record.courier_id}-${record.courier_name}- ${record.courier_city}`;
      case 'is_customer':
        return `${record.customer_id}-${record.customer_name}- ${record.customer_city}`;
    }
    return 'value';
  };
  const showUrlOption = () => {
    switch (issueSource) {
      case 'is_merchant':
        return `/merchants/`;
      case 'is_driver':
        return '/couriers/list';
      case 'is_customer':
        return '/customers/';
    }
    return 'value';
  };
  const createLabel = () => {
    switch (issueSource) {
      case 'is_merchant':
        return `Merchant`;
      case 'is_driver':
        return 'Driver';
      case 'is_customer':
        return 'Customer';
    }
    return 'value';
  };
  return (
    <>
      <Button
        size="large"
        type="primary"
        style={{
          borderRadius: '60px',
        }}
        onClick={onShowModal}
      >
        Create A ticket
      </Button>
      <Modal footer={null} open={state.showCreateTicketModal} onCancel={onCloseModal}>
        <Card>
          <Form
            form={form}
            layout="vertical"
            style={{
              width: '100%',
            }}
            initialValues={{
              issue_source: 'is_merchant' ,
            }}
            onFinish={onFinish}
          >
            <Row gutter={[10, 0]}>
              {hideTitle ? null : (
                <Col
                  span={24}
                  style={{
                    marginTop: -10,
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Create A Ticket
                  </span>
                </Col>
              )}
              <Col span={24}>
                <Form.Item name={'issue_source'} label={'Issue source'}>
                  <Select
                    onChange={() => {
                      form.resetFields(['field_value','issue_type_id'])
                    }}
                    options={[
                      { value: 'is_driver', label: 'Driver' },
                      { value: 'is_merchant', label: 'Merchant' },
                      { value: 'is_customer', label: 'Customer' },
                    ]}
                  />
                </Form.Item>
              </Col>
              {
                issueSource? <Col span={24}>
              
                <RemoteSelectForm
                  labelForm={createLabel()}
                    endpoint={showUrlOption()}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    style={{
                      width: '100%',
                    }}
                    valueOption={showValueOption()}
                    optionText={showLabelOption}
                    nameForm="field_value"
                    allowClear={true}
                    filterFromServer={true}
                    placeholder={'Select a issue'}
                    dependency={[issueSource]}
                    query={{
                      limit: 10,
                    }}
                  />
              </Col>:null
             }
            {
                issueSource?   <Col span={24}>
                <Form.Item>
                  <RemoteSelectForm
                    endpoint={'/telegram-bot-ticket/list'}
                    labelForm={'Issue'}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    style={{
                      width: '100%',
                    }}
                    valueOption={'value'}
                    labelOption={'label'}
                    nameForm="issue_type_id"
                    allowClear={true}
                    placeholder={'Select a issue'}
                    dependency={[issueSource]}
                    query={{
                      [issueSource||'is_merchant']: 1,
                    }}
                  />
                </Form.Item>
              </Col>:null
             }
              <Col
                span={18}
                style={{
                  marginTop: -30,
                }}
              >
                <Form.Item name={'description'} label="Description">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Button
                  style={{
                    width: '100%',
                    backgroundColor: '#4BB543',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </>
  );
};

export default CreateTicketComponentButton;
