import React, { useState } from 'react';
import {
    Button,
    Form,
    message,
    Modal,
    Upload,
  } from 'antd';

import {
    UploadOutlined,
  } from '@ant-design/icons';
import { useAxiosPost } from '../../configs/axios';
const DriverForcastingButton = () => {



    const [driverForcastModalVisible, setDriverForcastModalVisible] = useState(false);
    const [driverForm] = Form.useForm();

    const { loading: fibLoading, request: uploadForcastingCSV } =
    useAxiosPost('/driver-forcasting');

    const onUploadCSVSubmit= async (values) => {
        if (!(values && values.driverForcast)) {
            return true;
          }
      
          try {
            const formData = new FormData();
            formData.append('driverForcast', values.driverForcast[0].originFileObj);
            await uploadForcastingCSV(formData);
            message.success('an email with new schdule will be sent to you shortly');
            setDriverForcastModalVisible(false)
            // location.reload();
          } catch (error) {
            message.error('Error Uploading FIB Orders');
          }
    }

    return (
       <div>
         <Button
        size="large"
        type="primary"
        style={{
          borderRadius: '60px',
        }}
        onClick={() => setDriverForcastModalVisible(true)}
      >
       Forcast Drivers
      </Button>
      <Modal
        title="Forcast Drivers"
        destroyOnClose
        open={driverForcastModalVisible}
        onOk={() => {
          driverForm.submit();
        }}
        okButtonProps={{
        //   loading: fibLoading,
        }}
        onCancel={() => {
            setDriverForcastModalVisible(false);
        }}
      >
        <Form
          loading={true}
          form={driverForm}
          layout="vertical"
          onFinish={onUploadCSVSubmit}
        >
          <Form.Item
            name="driverForcast"
            label="CSV File"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              showUploadList={false}
              accept=".xlsx"
              maxCount={1}
              beforeUpload={() => false}
            >
              <Button
                // loading={fibLoading}
                icon={<UploadOutlined />}
              >
                Upload CSV File
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
       </div>
    )
}

export default DriverForcastingButton;