import { configureStore } from '@reduxjs/toolkit';
import { voucherEnumSlice } from './vouchers/voucherEnumSlice';

const store = configureStore({
  reducer: {
    enumSlice: voucherEnumSlice.reducer,
  },
});

export default store;
