import { lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Permission = lazy(() => import('./pages/Permission'));
const Chat = lazy(() => import('./pages/Chat'));
const CustomerChat = lazy(() => import('./pages/CustomerChat'));
const Users = lazy(() => import('./pages/Users'));
const FreeMeal = lazy(() => import('./pages/FreeMeal'));
const SendLezzooPay = lazy(() => import('./pages/SendLezzooPay'));
const CustomerAddress = lazy(() => import('./pages/CustomerAddress'));
const PredefinedMessages = lazy(() => import('./pages/PredefinedMessages'));
const Customers = lazy(() => import('./pages/Customers'));
const Broosk = lazy(() => import('./pages/Broosk'));
const MerchantHubSort = lazy(() => import('./pages/MerchantHubSort'));
const MerchantHubClose = lazy(() => import('./pages/MerchantHubClose'));
const Vouchers = lazy(() => import('./pages/Vouchers'));
const HubClosure = lazy(() => import('./pages/HubClosure'));
const VipCrud = lazy(() => import('./pages/VipCrud'))
const QA = lazy(() => import('./pages/QA'))
const RequestRefunds = lazy(() => import('./pages/RequestRefunds'))

const options = [
  {
    key: Math.random(),
    path: '/',
    component: Home,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/permissions',
    component: Permission,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/chat',
    component: Chat,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/chat-customer',
    component: CustomerChat,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/user-status',
    component: Users,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/free-meals',
    component: FreeMeal,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/send-lezzoopay',
    component: SendLezzooPay,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/customer-address',
    component: CustomerAddress,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/customers',
    component: Customers,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/predefined-messages',
    component: PredefinedMessages,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/broosk',
    component: Broosk,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/merchant-hub-close',
    component: MerchantHubClose,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/merchant-hub-sort',
    component: MerchantHubSort,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/vouchers',
    component: Vouchers,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/hub-closure',
    component: HubClosure,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/vip-merchants',
    component: VipCrud,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/quality-assurance',
    component: QA,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/request-refunds',
    component: RequestRefunds,
    exact: true,
  },
];
export default options;
