import React from 'react';
import { useAxiosGet } from '../../../configs/axios';
import { Table, } from 'antd';

function DriverCompensate({ selectedOrder }) {
  const {
    data: agentCompensateData,
    loading: agentCompensateLoading,
  } = useAxiosGet('compensate/driverCompensations', {
    autoRun: true,
    orderId: selectedOrder.orderId
  });


  const columns = [
    {
      title: 'ID',
      dataIndex: 'courier_id',
      key: 'courier_id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: 'Courier name',
      dataIndex: 'courier_name',
      key: 'courier_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
   
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];

  return (
    <div>
      <Table
        loading={agentCompensateLoading}
        dataSource={agentCompensateData}
        columns={columns}
      />
    </div>
  );
}

export default DriverCompensate