import React, { useState } from 'react';
import {
  Button,
  Form,
  Spin,
  Input,
  InputNumber,
  Select,
  message,
} from 'antd';
import { useAxiosPost } from '../../../configs/axios';
const CompensationTab = ({selectedOrder}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { request: compensateRequest } = useAxiosPost('/compensate/compensate');
  const handleSubmit = async (values) => {
    setLoading(true);
    const newValues = {
      orderId: selectedOrder.orderId,
      ...values,
    };

    await compensateRequest(newValues);

    message.success('Compensation Done Successfully');
    setLoading(false);
  };
  return (
    <div>
      <Form onFinish={handleSubmit} form={form}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Form.Item label="Amount" name="amount">
            <InputNumber
              style={{
                width: '200px',
              }}
              min={1}
              placeholder={'Amount'}
            
            />
          </Form.Item>
          <Form.Item label="Target" name="target">
            <Select
              style={{
                width: '200px',
              }}
              placeholder="Target"
            >
              <Select.Option key={'lezzoo'} value={'lezzoo'}>
                Lezzoo
              </Select.Option>
              <Select.Option key={'customer'} value={'customer'}>
                Customer
              </Select.Option>
              <Select.Option key={'merchant'} value={'merchant'}>
                Merchant
              </Select.Option>
              <Select.Option key={'driver'} value={'driver'}>
                Driver
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Extra Notes" name="description">
            <Input style={{ width: 200 }} placeholder="Extra Notes" />
          </Form.Item>
          <Button
            loading={loading}
            type="primary"
            style={{ marginBottom: 22 }}
            htmlType="submit"
          >
            Refund
          </Button>
        </div>
      </Form>
     
      <hr />
      { selectedOrder ? 
        <>
          <h4>Prevoius total refunds: {selectedOrder.totalOrderRefund}</h4>
          <h4>Order original price: {selectedOrder.orderTotal} </h4>
        </>
       : null}
    </div>
  );
};

export default CompensationTab;
