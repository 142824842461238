import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const rootElement = document.getElementById("root");
document.title = "Lezzoo Operations";
// change icon of the document
document
  .querySelector("link[rel='icon']")
  .setAttribute("href", "https://lezzoo.com/assets/Lezzoo.e57982f3.png");

const root = ReactDOM.createRoot(rootElement);

root.render(<App />);

